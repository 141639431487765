
























import { Component, Vue } from "vue-property-decorator";
import Navbar from "@/components/Navbar.vue";

@Component({
  components: {
    Navbar
  }
})
export default class App extends Vue {

  mounted() {
  //@ts-ignore
  //   this.$refs["my-modal"].show();
  }

  // hideModal() {
  //   this.$refs['my-modal'].hide()
  // }

}
