import { render, staticRenderFns } from "./Boards.vue?vue&type=template&id=1a126e90&scoped=true&"
import script from "./Boards.vue?vue&type=script&lang=ts&"
export * from "./Boards.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a126e90",
  null
  
)

export default component.exports