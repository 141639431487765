




















































































































import { Vue, Component } from 'vue-property-decorator';
import ContactForm from "@/components/ContactForm.vue";

@Component({
  components: {ContactForm}
})
export default class Contact extends Vue {


    firstname = "";
    surname = "";
    email = "";
    organization = ""


  onSubmit() {
    alert(
        "Fornavn: "+this.firstname+"\n"+
        "efternavn: "+this.surname+"\n"+
        "Email: "+this.email+"\n"+
        "Organisation: "+this.organization+"\n"
    );
    this.onReset();
  }

  onReset() {
    this.firstname = "";
    this.surname = "";
    this.email = "";
    this.organization = ""
  }

}


