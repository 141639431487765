








































































































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class ContactForm extends Vue {

  @Prop()
  showFullForm?: boolean;

  name = "";
  organization = "";
  telephone = "";
  email = "";
  users = "";
  comments = ""

  onSubmit() {
    alert(
        "Navn: "+this.name+"\n"+
        "Organisation: "+this.organization+"\n"+
        "Telefonnummer: "+this.telephone+"\n"+
        "Email: "+this.email+"\n"+
        "Antal brugere: "+this.users+"\n"+
        "Bemærkninger: "+this.comments+"\n"
    );
    this.reset();
  }

  reset() {
    this.name = "";
    this.organization = "";
    this.telephone = "";
    this.email = "";
    this.users = "";
    this.comments = ""
  }


}
