import Vue from 'vue'
import Vuex from 'vuex'
import {RootState} from "@/store/statetypes";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    languages: [],
    cookieAccept: false,
  },
  mutations: {
    SET_LANGUAGES(state: RootState, languages: Array<ILanguageSet>) {
      state.languages = languages;
    },
    SET_COOKIE_ACCEPT(state: RootState, accept: boolean) {
      state.cookieAccept = accept;
    },

  },
  actions: {},
  modules: {}
});
