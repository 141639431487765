import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Boards from "../views/Boards.vue";
import Meeting from "../views/Meeting.vue";
import GetStarted from "../views/GetStarted.vue";
import Prices from "../views/Prices.vue";
import Contact from "../views/Contact.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/boards",
    name: "Boards",
    component: Boards
  },
  {
    path: "/meeting",
    name: "Meeting",
    component: Meeting
  },
  {
    path: "/get-started",
    name: "GetStarted",
    component: GetStarted
  },
  {
    path: "/prices",
    name: "Prices",
    component: Prices
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  window.scrollTo(0,0);
})

export default router;
